/* Fonts */
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,700;1,500&family=Work+Sans&display=swap");
/* React Image Gallery styles (from node package) */
@import "~react-image-gallery/styles/css/image-gallery.css";
/* Boostrap Sass (from node package) */
@import "./scss/custom-bootstrap.scss";
@import "~bootstrap/scss/bootstrap";

/* This site's custom styles */
@import "./scss/styles.scss";
