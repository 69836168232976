@import "~bootstrap/scss/vendor/_rfs.scss";

// bootstrap variables' overrides
$enable-responsive-font-sizes: true;

$font-size-base:              1rem;
$h1-font-size:                $font-size-base * 4.5;
$h2-font-size:                $font-size-base * 2;
$h3-font-size:                $font-size-base * 1.85;
$h4-font-size:                $font-size-base * 1.5;
$h5-font-size:                $font-size-base * 1.25;
$h6-font-size:                $font-size-base * 1.125;

$lead-font-size:              $font-size-base * 1.5;

$font-family-sans-serif: "Open Sans", sans-serif;
$headings-font-family: "Nunito", sans-serif;
$headings-font-weight: 400;
$headings-color: #002B7F;

$text-primary-color: #002B7F;
$text-secondary-color: #B7C6E6;

$body-bg: #fafafa;
$body-color: #002B7F;

$primary: #f1f1f1;


/* import bootstrap to set changes */
@import "~bootstrap/scss/bootstrap";