/* Modal for individual project */
.project-modal {
  &.modal {
    padding: 0;
  }
  .modal-dialog {
    height: auto;
    width: auto;
    max-width: 90vw;
  }
  .modal-content {
    height: auto;
    padding-bottom: 1rem;
  }

  .project-modal-header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 1rem 1rem 1rem 1rem;
    border-bottom: 2px solid rgba(24, 23, 23, 0.05);
  }
  .project-modal-title {
    font-family: "Poppins", sans-serif;
    font-weight: 300;
    font-size: 1.25rem;
    align-self: center;
  }
}

.close {
  color: #002B7F !important;
  font-size: 36px !important;
  font-weight: 700 !important;
  font-family: "Nunito", sans-serif !important;
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}

.prototype-wrapper {
  position: relative;
  iframe {
    width: 100%;
    height: 80vh;
  }
}
.prototype-title {
  top: 0;
  bottom: initial !important;
  text-align: left;
  right: 75% !important;
}

/* Image Gallery - limit gallery height except for fullscreen*/
.image-gallery-content:not(.fullscreen) {
  .image-gallery-image {
    max-height: 80vh !important;
  }
}
.image-gallery-content.fullscreen {
  .image-gallery-slides,
  .prototype-wrapper iframe {
    height: 99vh !important;
  }
}
.image-gallery-icon:hover {
  color: $link-hover-color !important;
}

.image-gallery-thumbnail.active,
.image-gallery-thumbnail:hover,
.image-gallery-thumbnail:focus {
  border: 4px solid $link-hover-color !important;
}

/* React Image Gallery styles (from node package)*/
@import "~react-image-gallery/styles/css/image-gallery.css";
