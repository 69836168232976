/* CSS Animation */

.logo:hover {
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
}


@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}

.card-animation {
  opacity: 0;
  transform: translateY(4em) translateX(-5em);
  transition: transform 4s 0.5s cubic-bezier(0, 1, 0.3, 1),
    opacity 0.3s 0.5s ease-out;
  will-change: transform, opacity;
}

.card.show {
  opacity: 1;
  transform: translateY(-0.15em);
  visibility: visible;
  animation: 0.6s zoom-in;
}

.siteFooter {
  opacity: 0;
  transition: opacity 2s 0.25s ease-out;
  will-change: opacity;
}

.siteFooter.is-visible {
  opacity: 1;
}

.is-animated {
  animation: 0.6s zoom-in;
}

@keyframes zoom-in {
  0% {
    transform: scale(0.1);
  }
  100% {
    transform: none;
  }
}

.avatar:hover {
  animation-name: rubberBand;
  animation-duration: 1s;
  animation-fill-mode: both;
  animation-iteration-count: 1;
}

.arrow {
  position: relative;
  bottom: -2rem;
  left: 50%;
  margin-left: -20px;
  width: 50px;
  height: 50px;
  opacity: 0.5;
}

.bounce {
  animation: bounce 2s infinite;
  animation-timing-function: ease;
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: scale(1,1) translateY(0);
  }
  40% {
    transform: scale(.9,1.1) translateY(30px);
  }
  60% {
    transform: scale(1,1) translateY(15px);
  }
}
