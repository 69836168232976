/* Fonts */
// @import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,700;1,500&family=Work+Sans&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;600;700;800;900&family=Open+Sans:wght@300;400;600;700;800&display=swap');
/* Legacy custom styles */
@import "./css/animations.css";
@import "./css/hamburger.css";

@import "_form.scss";
@import "project.scss";

$leading-paragraph: 1.8;
$link-color: $text-primary-color;
$link-hover-color: $text-secondary-color;



html {
  scroll-behavior: smooth;
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  transform: translateY(0);
  transition: transform 0.3s ease;
}

.header.hidden {
  transform: translateY(-110%);
}

.navMenu {
  color: $link-color !important;
  font-weight: 600;
}

.navMenu:hover {
  text-decoration: underline;
  text-decoration-color: $link-hover-color;
  text-decoration-thickness: 0.2em;
}

.main-container {
  margin-top: 80px !important;
}

/* Modal for individual project */

.project-modal {
  &.modal {
    padding: 0;
  }
  .modal-dialog {
    height: auto;
    width: auto;
    max-width: 90vw;
  }
  .modal-content {
    height: auto;
    padding-bottom: 1rem;
  }
  
  .project-modal-header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 1rem 1rem 1rem 1rem;
    border-bottom: 2px solid rgba(24, 23, 23, 0.05);
  }
  .project-modal-title {
    font-family: $headings-font-family;   // bootstrap variable, see custom-bootstrap
    font-weight: 300;
    font-size: 1.25rem;
    align-self: center;
  }
}


/* Responsive player for projects with video */
.player-wrapper {
  position: relative;
  padding-top: 56.25%; // Player ratio: 100 / (1280 / 720)
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}

/* Image Gallery - limit gallery height except for fullscreen*/
.image-gallery-content:not(.fullscreen) .image-gallery-image {
  max-height: 80vh !important;
}
.image-gallery-icon:hover {
  color: $link-hover-color !important;
}

/* Navigation bar */
.navbar-toggler {
  border: none;
}

.navToggle:focus,
.navToggle:active,
.hamburger:focus {
  outline: none;
  box-shadow: none;
}

.hamburger-inner,
.hamburger-inner::before,
.hamburger-inner::after {
  background-color: $text-primary-color !important;
}

.navbar-nav {
  padding: 10px 10px 10px 10px;
  font-family: $headings-font-family;   // bootstrap variable, see custom-bootstrap
  font-weight: 400;
  font-size: 15px;
  letter-spacing: 1px;
  list-style-type: none;
}

.logoImg {
  width: 50px;
  border-radius: 0px;
}

a {
  text-decoration: none;
}

.nav-link {
  margin: 0 1rem 0 .5rem;
}

.contact-icon {
  margin-left: .4rem;
}

/* Hero area */

.hero-img {
  max-width: 100%;
}

.hero-container-spacing {
  padding: 7.5% 5% 7.5% 5%;
}

.info {
  max-width: 100%;
}

.col-sm {
  padding-left: 5px;
  padding-right: 5px;
}

.infoText p {
  font-style: normal;
  font-weight: 400;
  line-height: $leading-paragraph;
  font-size: 1.2rem;
}

.infoText > h1 {
  font-weight: 700;
  margin-bottom: 1rem;
}

.infoText > h3 {
  margin-bottom: 1.2em;
}

.infoText > .typewrite {
  font-weight: 900;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: $text-primary-color;
}

h2 {
  font-weight: 600;
}

.infoText > h3 {
  font-weight: 400;
  opacity: 0.9;
}

.base-container {
  padding: 7.5% 3% 15% 3%;
}

@mixin animate($name, $duration, $iteration, $direction) {
	-webkit-animation-duration: $duration;
	   -moz-animation-duration: $duration;
	     -o-animation-duration: $duration;
		    animation-duration: $duration;

	-webkit-animation-iteration-count: $iteration;
	   -moz-animation-iteration-count: $iteration;
	     -o-animation-iteration-count: $iteration;
	        animation-iteration-count: $iteration;

	-webkit-animation-name: $name;
	   -moz-animation-name: $name;
	     -o-animation-name: $name;
	        animation-name: $name;

	-webkit-animation-direction: $direction;
	   -moz-animation-direction: $direction;
	     -o-animation-direction: $direction;
		    animation-direction: $direction;
}

@mixin keyframes($animationName) {
    @-webkit-keyframes #{$animationName} {
        @content;
    }
    @-moz-keyframes #{$animationName} {
        @content;
    }
    @-o-keyframes #{$animationName} {
        @content;
    }
    @keyframes #{$animationName} {
        @content;
    }
}

@include keyframes(blinker) {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@mixin blinker {
  @include animate(blinker, 0.6s, infinite, normal);
}

.blinker {
  @include blinker;
}


/* Projects */

.card-custom-styles {
  padding-top: 0px;
  padding-right: 0px;
  margin: 15px !important;
  border-radius: 20px;
  float: left;
  border: none;
  cursor: pointer;
}

.card-img-top {
  border-radius: 20px 20px 0px 0px;
}

.card h6,
.proj-info h6 {
  margin-top: 10px;
}

.card p {
  padding-top: 16px;
  text-align: left;
  line-height: $leading-paragraph;
  letter-spacing: 0.05rem;
}

.card-shadow {
  // background: linear-gradient(145deg, #dfe0e1, #ffffff); , -8px -8px 16px #ffffff
  box-shadow: 5px 5px 20px #6b71778f;
}

.cards-container {
  margin: 30px 5px 0px 5px;
}

.projects-menu {
  margin-top: 15px;
  text-align: center;
}

/* Projects Category Filters */

#projects {
  padding-top: 100px;
}

.title-text > h1 {
  font-weight: 300;
  font-size: 3.75rem;
  margin-bottom: 15px;
  // border-bottom: 2px solid rgba(24, 23, 23, 0.05);
  text-align: center;
}

.title-section,
.title-text {
  padding: 0px;
}

/* Style the buttons */
.btn,
.btn-primary {
  font-family: $headings-font-family;   // bootstrap variable, see custom-bootstrap
  font-weight: 700;
  font-size: 15px;
  color: $text-primary-color;
  background-color: transparent;
  padding: 12px 20px;
  border: 2px solid $text-primary-color;
  border-radius: 30px;
  cursor: pointer;
  &:hover {
    background-color: $text-secondary-color;
    border: 2px solid $text-primary-color;
  }
  &:focus {
    background-color: $text-secondary-color;
    border: 2px solid $text-primary-color;
  }
}

/* Add a dark background to the active button */
.btn.active {
  font-weight: 800;
  background-color: $text-secondary-color;
  box-shadow: inset 4px 4px 0px white,
                    4px 4px 0px $text-secondary-color;
}

.row {
  margin-left: 0px;
  margin-right: 0px;
}

// Cards button
.projButton {
  font-family: $headings-font-family;   // bootstrap variable, see custom-bootstrap
  font-weight: 700;
  font-size: 15px;
  color: $text-primary-color;
  background-color: $text-secondary-color;
  padding: 12px 20px;
  border: 2px solid $text-primary-color;
  border-radius: 30px;
  box-shadow: inset 4px 4px 0px white,
                    4px 4px 0px $text-secondary-color;

  &:hover {
    background-color: $text-secondary-color;
    box-shadow: none;
  }
}

.heroButton {
  font-family: $headings-font-family;   // bootstrap variable, see custom-bootstrap
  font-weight: 400;
  font-size: 24px;
  color: $text-primary-color;
  background-color: $text-secondary-color;
  padding: 12px 50px;
  border: 2px solid $text-primary-color;
  border-radius: 30px;
  margin-top: 10px;
  margin-bottom: 15px;
  box-shadow: inset 4px 4px 0px white,
                    4px 4px 0px $text-secondary-color;

  &:hover {
    background-color: $text-secondary-color;
    box-shadow: none;
  }
}

/* About */
.about-container {
  p {
    line-height: $leading-paragraph;
  }
}

.about-logo {
  width: calc(10rem + 10vw);
  padding-bottom: 2rem;
}

.contact-icons-nav {
  flex-direction: row !important;
}
.contact-icon {
  padding-right: 10px;
  text-decoration: none;
  font-size: x-large;
  color: $link-color;
  &:hover {
    text-decoration: none;
    color: $link-hover-color;
  }
}
.contact-icon .contact-icon-content {
  font-size: 1.8rem;
  padding-bottom: 3px;
}

/* Footer */

footer {
  height: auto;
  background-color: white;
  border-top: 2px solid rgba(24, 22, 22, 0.1);
  margin-top: 0px;
}

.footerContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
  padding: 5px;
  text-align: center;
}

.copyright {
  font-size: 0.7rem;
  margin-bottom: 0;
}

#topButton.btn {
  background: rgba(0, 0, 0, 0);
  color: $link-color;
  box-shadow: none;
  font-size: x-large;
  border: none;
}

#topButton.btn:hover {
  color: $link-hover-color;
}

// Larger Screens
@media (min-width: 768px) {
  .navbar {
    padding: 0.5% 7% !important;
  }

  .large-container {
    max-width: 1900px;
    margin: auto;
  }
  .base-container {
    padding: 2.5% 5% 15% 5%;
  }
  .about-container {
    padding-left: 20%;
    padding-right: 20%;
  }

  .card-custom-styles {
    max-width: 30%;
    margin: 10px;
  }

  .cards-container {
    margin: 30px 30px 100px 30px;
  }

  .navToggle {
    display: none;
  }

  .navContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    position: relative;
    margin-top: 10px;
    padding-top: 0px;
    max-width: 50%;
    border-bottom: none;
  }

  .nav-link {
    border-bottom: 3px solid transparent;
    margin: 0 1rem 0 .5rem;
    &:hover {
      text-decoration: none;
      border-bottom-color: $link-hover-color;
      background-color: transparent;
    }
  }

  @keyframes zoom-in {
    0% {
      transform: scale(0.1);
    }
    100% {
      transform: none;
    }
  }

  /* Hero area */

  .hero-img {
    margin: 0;
    max-width: 100%;
  }

  .hero-container-spacing {
    padding: 1rem 2.5rem 1rem 2.5rem;
  }
}
