textarea {
  resize: none;
}

.form-control {
  background: #white;
  border: 2px solid $text-primary-color;
  min-width: 30vw;
  border-radius: 10px;
  &:focus {
    background: white;
    border: 2px solid $text-primary-color;
    box-shadow: 3px 3px 10px #a7a7a7, inset -3px -3px 10px #ffffff;
  }
}

::placeholder {
  color: $text-primary-color !important;
  opacity: 0.7 !important;
}
